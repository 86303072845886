<template>
  <svg-icon :data="sData" v-bind="$attrs" class="pkg-lineicons" />
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class IconCollect extends Vue {
  sData: string = require("@icon/lineicons/box-1.svg");
}
</script>
